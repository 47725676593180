import './App.css';
import HomePage from './pages/HomePage';
import NoMatch from './pages/NoMatch';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactPage from './pages/ContactPage';
import AboutUsPage from './pages/AboutUsPage';



function App() {
  return (
    <div className="App">
    <Navbar/>
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/about' element={<AboutUsPage/>}/>
      <Route path='/contact' element={<ContactPage/>}/>
      <Route path="*" element={<NoMatch/>} />
    </Routes>
    <Footer/>
  </div>
  );
}

export default App;
