// ContactPage.js

import React from "react";
import { FaEnvelope } from "react-icons/fa";

const ContactPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-2xl">
        <h1 className="text-4xl font-bold mb-8 text-center">Contact Us</h1>

        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold mb-2">Get in Touch</h2>
          <p>
            We would love to hear from you! If you have any inquiries,
            partnership proposals, or just want to connect, please reach out to
            us using the contact information below
          </p>
        </div>

        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold mb-2">Email</h2>
          <p>
            <strong>Email:</strong>{" "}
            <a
              href="mailto:contact@culinovate.com"
              className="flex items-center justify-center text-blue-500"
            >
              <span className="mr-2">
                <FaEnvelope />
              </span>
              contact@culinovate.com
            </a>
          </p>
        </div>

        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold mb-2">Phone</h2>
          <p>
            <strong>Phone:</strong> +965 99665528
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
