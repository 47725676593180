import React from "react";

const HomePage = () => {
  return (
    <>
      <div className="flex flex-wrap pt-8 bg-gray-100">
        <div className="w-full">
          <img
            src={require("../images/food.jpg")}
            className="w-full max-h-96 object-cover"
            alt=""
          />
        </div>
        <div className="w-full p-4">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
            Welcome to Culinovate!
          </h1>
          <p className="text-base md:text-lg lg:text-xl mb-4">
            Welcome to Culinovate, where innovation meets a legacy of excellence
            in the world of food! As a dynamic and newly founded company
            operating under the umbrella of a distinguished sister company with
            over 25 years of experience in the market, we at Culinovate are on a
            mission to redefine the landscape of health-oriented food products.
          </p>
          <p className="text-base md:text-lg lg:text-xl mb-4">
            Our commitment to delivering top-notch, nutritionally rich offerings
            is fueled by a passion for wellness and a deep-rooted understanding
            of the evolving needs of our consumers. Drawing inspiration from the
            wealth of expertise within our larger sister company, we embark on
            this journey with a vision to become leaders in the health-oriented
            food industry.
          </p>
          <p className="text-base md:text-lg lg:text-xl mb-4">
            At Culinovate, we stand at the intersection of tradition and
            innovation, leveraging the invaluable knowledge and reputation of
            our esteemed sister company while charting a course towards the
            future of health-conscious food experiences. Join us on this
            exciting adventure as we blend decades of industry wisdom with a
            fresh perspective, creating a unique space where flavor, health, and
            quality converge. Together, let's savor the taste of a healthier
            tomorrow with Culinovate.
          </p>
        </div>
      </div>
      <div style={{ height: 100 }} className="bg-gray-100"></div>
    </>
  );
};

export default HomePage;
