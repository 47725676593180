import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navlinks = [
    { id: 1, name: "Home", path: "/" },
    { id: 2, name: "About us", path: "/about" },
    { id: 3, name: "Contact", path: "/contact" },
  ];

  return (
    <nav className="relative px-4 py-4 flex justify-between items-center bg-white">
      <a href="/">
        <img
          src={require("../images/culinovate-logo2.png")}
          width={160}
          alt=""
        />
      </a>

      <div className="relative">
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-black p-3"
            onClick={toggleDrawer}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <div
          className={`${
            isDrawerOpen ? "translate-x-0" : "translate-x-full"
          } transform top-0 right-0 w-64 h-full bg-gray-800 fixed lg:hidden transition-transform ease-in-out duration-300`}
          style={{
            boxShadow:
              "0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)",
            zIndex: 999,
            borderRadius: "8px",
          }}
        >
          <button
            className="absolute top-3 left-3 text-white"
            onClick={toggleDrawer}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M20.293 4.293a1 1 0 00-1.414-1.414L12 10.586 5.121 3.707a1 1 0 00-1.414 1.414L10.586 12l-6.889 6.879a1 1 0 101.414 1.414L12 13.414l6.879 6.889a1 1 0 001.414-1.414L13.414 12l6.879-6.889z"
              />
            </svg>
          </button>
          <ul className="mt-16 space-y-4 px-4">
            <li>
              <div className="bg-gray-700 p-2 rounded-md">
                <a
                  href="/"
                  className="block text-white hover:text-gray-300 font-semibold transition duration-300"
                  onClick={toggleDrawer}
                >
                  Home
                </a>
              </div>
            </li>
            <li>
              <div className="bg-gray-700 p-2 rounded-md">
                <a
                  href="/about"
                  className="block text-white hover:text-gray-300 font-semibold transition duration-300"
                  onClick={toggleDrawer}
                >
                  About us
                </a>
              </div>
            </li>
            <li>
              <div className="bg-gray-700 p-2 rounded-md">
                <a
                  href="/contact"
                  className="block text-white hover:text-gray-300 font-semibold transition duration-300"
                  onClick={toggleDrawer}
                >
                  Contact
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
        {navlinks.map((e, i) => {
          if (i === navlinks.length - 1) {
            return (
              <div key={e.id}>
                <li>
                  <NavLink
                    className="text-sm text-gray-400 hover:text-gray-500 focus:text-black focus:font-bold"
                    to={e.path}
                  >
                    {e.name}
                  </NavLink>
                </li>
              </div>
            );
          }

          return (
            <>
              <div key={e.id}>
                <li>
                  <NavLink
                    className="text-sm text-gray-400 hover:text-gray-500 focus:text-black focus:font-bold"
                    to={e.path}
                  >
                    {e.name}
                  </NavLink>
                </li>
              </div>
              {threeDots}
            </>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;

const threeDots = (
  <li className="text-gray-300">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      className="w-4 h-4 current-fill"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
      />
    </svg>
  </li>
);
