// AboutUsPage.js

import React from "react";

const AboutUsPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-3xl px-5 py-8">
        <h1 className="text-4xl font-bold mb-8 text-center">About Us</h1>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Logistical Excellence</h2>
          <p className="text-left">
            At Culinovate, our logistical infrastructure is designed with
            precision and formality to ensure the optimal preservation and
            distribution of our imported food products. Our year-round
            operational A/C cooled storage facility serves as a controlled
            environment, maintaining the quality and freshness of our diverse
            range of products.
          </p>
          <p className="text-left">
            Complementing this facility, our transportation fleet represents a
            sophisticated aspect of our logistics. Deployed with efficiency and
            precision, our fleet is instrumental in the systematic dispatch of
            premium food products to various marketplaces. From urban centers to
            local retailers and specialty stores, our logistical team ensures
            the timely and precise delivery of our products, adhering to the
            highest standards of quality. At Culinovate, our commitment to
            formality extends beyond our products to the very logistics that
            bring them to your doorstep.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">Nature of Affiliation</h2>
          <p className="text-left">
            The affiliation with our sister company is characterized by
            deep-rooted connections within the market, facilitating a seamless
            listing journey for our products. These established connections
            minimize time-to-market, ensuring efficient product launches and
            market penetration.
          </p>
          <p className="text-left">
            The financial aspect of our affiliation is bolstered by an expert
            financial team provided by our sister company. This team brings a
            wealth of experience and knowledge to our startup, enabling us to
            navigate financial landscapes with precision and strategic
            foresight.
          </p>
          <p className="text-left">
            Additionally, our affiliation extends to include an experienced
            sales team. This dedicated sales force plays a crucial role in
            supporting our market entry and expansion efforts, leveraging their
            expertise to drive sales and enhance our market presence.
          </p>
        </div>

        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold mb-2">
            Shared Resources, Expertise, and Support
          </h2>
          <p className="text-left">
            The affiliation provides access to shared resources, including
            infrastructure, technology, and logistical support. This allows the
            startup to operate efficiently and scale operations as needed.
          </p>
          <p className="text-left">
            Expertise is a key shared asset. The startup benefits from the
            knowledge and experience of the sister company's professionals,
            especially in financial management, market analysis, and strategic
            planning.
          </p>
          <p className="text-left">
            Ongoing support is a hallmark of our affiliation. Whether it's
            leveraging the sister company's established relationships for
            business development or tapping into their market insights, our
            startup is well-supported, fostering growth and resilience in a
            competitive landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
